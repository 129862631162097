import React from 'react'

const Header = () => {

  return (
    <div>
      <h1 className='header'>Get your dream job here!</h1>
      <h1 className="website">JobSearch.com</h1>
    </div>
  )
}

export default Header
